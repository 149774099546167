import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import RenderImage from "~wrappers/renderImage";
import { RenderMD } from "~wrappers/renderContent";

import { localizedUrl } from "~utils";

const PeekComponent = ({ data, location, locale }) => {
  return (
    <section className="stf-peek">
      <RenderImage
        image={data.img}
        modifiers={{
          className: "stf-peek__background",
        }}
      />
      <div className="stf-peek__overall-container">
        <div className="stf-container">
          <div className="columns">
            <div className="column is-6 is-5-desktop">
              <div className="stf-peek__overall-container__text-container">
                <h2 className="stf-label">{data.sectionName}</h2>
                <h2>{data.title}</h2>
                <RenderMD content={data.text} />
              </div>
            </div>
            <div className="column is-6 is-7-desktop">
              <div className="stf-peek__overall-container__button-container">
                <div className="stf-peek__overall-container__centered-container">
                  <AnchorLink
                    to={localizedUrl(
                      locale,
                      data.button.path.includes("current")
                        ? location.pathname.substring(1) + data.button.path
                        : data.button.path
                    ).slice(0, -1)}
                    stripHash
                    className="stf-button stf-button--ghost-white"
                  >
                    {data.button.text}
                  </AnchorLink>
                  <p>{data.textUnder}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PeekComponent;
