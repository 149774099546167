import React, { useEffect } from "react";
import Modal from "react-modal";

const DialogComponent = ({
  children,
  data: { id, title, status },
  dialogToggle,
}) => {
  useEffect(() => {
    Modal.setAppElement("#___gatsby");
  });

  if (!id || !title || !status || !dialogToggle) {
    return "";
  }

  return (
    <Modal
      isOpen={status}
      contentLabel={id}
      id={id}
      className={{
        base: "stf-dialog",
        afterOpen: "stf-dialog__after-open",
        beforeClose: "stf-dialog__before-close",
      }}
      overlayClassName={{
        base: "stf-dialog-overlay",
        afterOpen: "stf-dialog-overlay__after-open",
        beforeClose: "stf-dialog-overlay__before-close",
      }}
      closeTimeoutMS={200}
    >
      <div className="stf-dialog-header">
        <h2>{title}</h2>
        <button
          className="stf-dialog-close"
          onClick={(e) => {
            dialogToggle(false);
            e.target.blur();
          }}
        >
          <img src="/uploads/general/icon-close_black.svg" alt="X" />
        </button>
      </div>
      <div className="stf-dialog-content">{children}</div>
    </Modal>
  );
};

export default DialogComponent;
